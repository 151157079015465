/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ArrowForward as ArrowForwardIcon, Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import {
    Box,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Autocomplete
} from '@mui/material'
import { useTheme, alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDebouncedEffect from 'use-debounced-effect-hook'

import useUrls from '../../hooks/useUrls'

import FilterComponent from './FilterComponent'
import type { StateType } from './redux'
import { fetchSuggestions, setValue } from './redux'
import './SearchForm.css'
import { getSearchFilters } from './searchutils'

const useStyles = makeStyles(theme => ({
    searchForm: {
        '& header': {
            boxShadow: 'none',
            backgroundColor: 'inherit'
        },
        '& .Mui-selected': {
            opacity: '0.7'
        },
        '& [class*="MuiOutlinedInput-root"]': {
            padding: 0
        }
    },
    listbox: {
        margin: theme.spacing(1)
    },
    option: {
        padding: '4px 16px 4px 4px !important'
    },
    paper: {
        marginTop: 0,
        marginLeft: 4,
        borderRadius: '4px !important',
        borderColor: theme.palette.secondary.main,
        borderStyle: 'solid !important',
        borderWidth: '1px !important'
    },
    paperNoBorder: {
        border: 'none !important'
    },
    divider: {
        backgroundColor: theme.palette.text.disabled,
        height: 30,
        marginRight: '0px !important',
        marginLeft: '10px !important'
    },
    filterButton: {
        marginLeft: theme.spacing(2),
        borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
        border: `1px solid ${alpha(theme.palette.secondary.main, 0.15)}`,
        borderRadius: '2px',
        backgroundColor: `${alpha(theme.palette.secondary.main, 0.15)}`,
        opacity: 0.7,
        height: 40
    },
    courseLink: {
        color: 'rgba(255, 255, 255, 0.7)',
        '& hover': {
            color: '#b2ecf5'
        }
    }
}))

const searchResultTypeDisplay = {
    'case modules': gettext('Case Modules'),
    courses: gettext('Courses'),
    lectures: gettext('Lectures'),
    scripts: gettext('Scripts'),
    lists: gettext('Lists')
}

export default function SearchFormComponent({
    autoFocus = false,
    showFilterSection,
    showFilterIcon,
    onSubmit
}: {
    autoFocus?: boolean
    showFilterSection: boolean
    showFilterIcon: boolean
    onSubmit?: (...args: any) => any
}) {
    const classes = useStyles()
    const theme = useTheme()
    const formRef = useRef<HTMLFormElement>()
    const urls = useUrls()
    const dispatch = useDispatch()
    const suggestions = useSelector((state: StateType) => state.suggestions)
    const value = useSelector((state: StateType) => state.value)
    const [isPopperOpen, setPopperOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedFilters, setSelectedFilters] = React.useState(getSearchFilters)
    const handleSelectedFiltersChanged = useCallback(setSelectedFilters, [])
    const [isFocused, setIsFocused] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [value])

    useDebouncedEffect(
        () => {
            if (isFocused) {
                dispatch(fetchSuggestions(value))
            }
        },
        [dispatch, isFocused, value],
        300
    )

    useEffect(() => {
        setPopperOpen(suggestions.length > 0)
        setLoading(false)
    }, [suggestions])

    useEffect(() => {
        handleSelectedFiltersChanged(selectedFilters)
    }, [handleSelectedFiltersChanged, selectedFilters])

    const onClearSearch = () => {
        dispatch(setValue(''))
        setSelectedFilters({})
    }

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    const handlePopperOpening = () => {
        if (!isPopperOpen && suggestions.length > 0) {
            setPopperOpen(true)
        }
    }

    const handlePopperClosing = () => {
        if (isPopperOpen) {
            setPopperOpen(false)
        }
    }

    const saveSearchStringInHistory = () => {
        const url = new URL(window.location.toString())
        url.searchParams.set('search', value)
        window.history.pushState({}, '', url)
    }

    return (
        <form
            action={urls.courses()}
            ref={formRef}
            method="get"
            data-testid="searchForm"
            className={classes.searchForm}
            onSubmit={onSubmit}
        >
            <input name="filters" value={JSON.stringify(selectedFilters)} readOnly type="hidden" />
            <Box display="flex" flexDirection="row" alignItems="center">
                <Autocomplete
                    classes={{
                        listbox: classes.listbox,
                        option: classes.option,
                        paper: isPopperOpen ? classes.paper : classes.paperNoBorder
                    }}
                    disableClearable
                    forcePopupIcon={false}
                    freeSolo
                    fullWidth
                    onOpen={handlePopperOpening}
                    onClose={handlePopperClosing}
                    groupBy={option => searchResultTypeDisplay[option.type]}
                    // Do not filter out any options
                    filterOptions={options => options}
                    inputValue={value}
                    onInputChange={(event, value, reason) => {
                        // Autocomplete wants to reset the input initially. We don't want that here.
                        if (reason === 'reset') {
                            return
                        }
                        dispatch(setValue(value))
                    }}
                    onChange={(event, value, reason) => {
                        if (reason === 'selectOption') {
                            const url = value.type === 'courses' ? value.course_url : value.url
                            saveSearchStringInHistory()
                            window.location.assign(url)
                        }
                    }}
                    options={suggestions}
                    getOptionLabel={option => {
                        if (typeof option === 'string') {
                            return option
                        }
                        return option.type === 'courses' ? option.course.title : option.title
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            autoFocus={autoFocus}
                            focused={isFocused}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            type="text"
                            variant="outlined"
                            name="search"
                            placeholder={gettext('Search terms...')}
                            style={{ outline: 'none', boxSizing: 'border-box' }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <IconButton title={gettext('Search')} type="submit">
                                        <SearchIcon htmlColor={theme.palette.secondary.main} />
                                    </IconButton>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {loading && <CircularProgress size={20} />}
                                        {value && (
                                            <IconButton
                                                type="button"
                                                title={gettext('Clear search and filter fields')}
                                                onClick={onClearSearch}
                                                edge="end"
                                                data-testid="clearButton"
                                            >
                                                <ClearIcon htmlColor={theme.palette.secondary.main} />
                                            </IconButton>
                                        )}
                                        <Divider light orientation="vertical" className={classes.divider} />
                                        <IconButton
                                            style={{ marginRight: theme.spacing(1) }}
                                            title={gettext('Search')}
                                            type="submit"
                                            edge="end"
                                        >
                                            <ArrowForwardIcon htmlColor={theme.palette.secondary.main} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <Box pl={3} display="flex" flexDirection="column" alignItems="stretch" width="100%">
                                <Link variant="body1" onClick={event => event.preventDefault()} href={option.url}>
                                    {option.title}
                                </Link>
                                {option.type !== 'courses' && option.type !== 'lists' && (
                                    <Link
                                        onClick={event => event.preventDefault()}
                                        className={classes.courseLink}
                                        href={option.course_url}
                                        variant="body2"
                                    >
                                        {option.course.title}
                                    </Link>
                                )}
                            </Box>
                        </li>
                    )}
                />
                {showFilterIcon && (
                    <IconButton className={classes.filterButton} type="submit" size="large">
                        <FontAwesomeIcon icon={faFilter} size="2xs" />
                    </IconButton>
                )}
            </Box>
            {showFilterSection && (
                <React.Fragment>
                    <Divider
                        style={{
                            marginBottom: 0,
                            marginTop: 0,
                            backgroundColor: isFocused ? theme.palette.primary.main : ''
                        }}
                    />
                    <FilterComponent selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                </React.Fragment>
            )}
        </form>
    )
}
