/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { createAction, createReducer } from '@reduxjs/toolkit'
import axios from 'axios'

const CancelToken = axios.CancelToken
let pendingRequest = null
const MIN_VALUE_LENGTH = 2

export const initialState = {
    isLoggedIn: false,
    userIsOnlyInTraineeGroup: false,
    value: '',
    suggestions: [],
    registrationOpen: false
}

export const fetchSuggestions = value => async dispatch => {
    if (value.length < MIN_VALUE_LENGTH) {
        return
    }

    try {
        const response = await axios.get(window.Urls['search:search_as_you_type'](), {
            cancelToken: pendingRequest ? pendingRequest.token : null,
            params: { search: value }
        })
        dispatch(fetchSuggestionsSuccess(response.data))
    } catch (e) {
        if (axios.isCancel(e)) {
            dispatch(fetchSuggestionsCancel())
            return
        }
        dispatch(fetchSuggestionsError())
    }
}

export const setValue = createAction<string>('SET_VALUE')
export const fetchSuggestionsCancel = createAction('FETCH_SUGGESTIONS_CANCEL')
export const fetchSuggestionsError = createAction('FETCH_SUGGESTIONS_ERROR')
export const fetchSuggestionsSuccess = createAction<any>('FETCH_SUGGESTIONS_SUCCESS')

const reducer = createReducer(initialState, {
    [setValue.toString()]: (state, action) => {
        if (pendingRequest) {
            pendingRequest.cancel()
            pendingRequest = null
        }

        const value = action.payload
        if (value.length < MIN_VALUE_LENGTH) {
            return { ...state, value, suggestions: [] }
        }
        pendingRequest = CancelToken.source()
        return { ...state, value }
    },
    [fetchSuggestionsError.toString()]: state => ({ ...state, suggestions: [] }),
    [fetchSuggestionsSuccess.toString()]: (state, action) => ({ ...state, suggestions: action.payload })
})
export type StateType = ReturnType<typeof reducer>

export default reducer
